.snipe {
  padding: 50px 0;

  &__row {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media only screen and (max-width: toEm(1180px)) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  &__content {
    align-self: center;
    background: linear-gradient(to right, palette('orange'), palette('pink'));
    border-radius: 13px 0 0 13px;
    flex-grow: 1;
    padding: 2rem;
    height: 296px;

    @media only screen and (max-width: toEm(1180px)) {
      background: linear-gradient(to right, palette('pink'), palette('orange'));
      height: auto;
      width: inherit;
      max-width: 648px;
      border-radius: 0 0 13px 13px;
    }

    @media only screen and (max-width: toEm(400px)) {
      padding: 1rem;
    }
  }

  &__lede {
    color: palette('black');
    font-size: 1.25em;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__heading {
    line-height: 1.2;
    font-size: toRem(32px);
    text-transform: uppercase;
  }

  &__details {
    line-height: 1.7;
  }

  &__image {
    border-radius: 0 13px 13px 0;

    @media only screen and (max-width: toEm(1180px)) {
      border-radius: 13px 0 0 0;
    }
  }
}
