.header {
  background: center top no-repeat;
  color: #fff;
  padding: 150px 0 215px;
  position: relative;
  text-align: center;

  @media only screen and (min-width: 1001px) {
    background-image: url('/images/header.jpg?v=2') ;
  }

  @media only screen and (max-width: 1000px) and (min-width: 601px) {
    background-image: url('/images/header-medium.jpg?v=2') ;
  }

  @media only screen and (max-width: 600px) {
    background-image: url('/images/header-small.jpg?v=2') ;
    padding-top: 75px;
  }

  &__heading {
    font-size: toRem(36px);
    line-height: 1.2;
    text-transform: uppercase;

    @media only screen and (max-width: 600px) {
      margin-left: auto;
      margin-right: auto;
      max-width: 250px;
    }
  }

  &__arrow {
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
}
