.cta {
  background: linear-gradient(to right, palette('pink'), palette('orange'));
  color: #fff;
  display: inline-block;
  font-size: toRem(16px);
  padding: 10px 40px;
  text-align: center;
  text-decoration: none;

  &:hover {
    background: palette('pink');
  }

  @media only screen and (max-width: toEm(600px)) {
    padding: 10px;
    width: 100%;

    &--small {
      padding: 10px 40px;
      width: auto;
    }
  }
}
