.apps {
  display: flex;

  &__link {
    display: block;
    margin-left: 15px;

    &:first-child {
      margin-left: 0;
    }
  }

  @media only screen and (max-width: 600px) {
    justify-content: center;
  }
}
