/* -------------------------------------------------------------------------- *
 * Baseline Modules *
 * -------------------------------------------------------------------------- */

@import './_modules/functions';
@import './_modules/variables';
@import './_modules/mixins';
@import './_modules/reset';
@import './_modules/helper';

/* -------------------------------------------------------------------------- *
 * General Styles *
 * -------------------------------------------------------------------------- */

html {
  background-color: #000;
  color: #fff;
  font-family: fontStack('default');
  font-size: toRem(settings('root_font_size'));
  line-height: settings('line_height');
}

::selection {
  background-color: palette('blue');
}

strong {
  font-weight: 800;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: settings('width');
  padding-left: settings('gutter');
  padding-right: settings('gutter');

  @media only screen and (max-width: toEm(500px)) {
    padding-left: settings('gutter')/2;
    padding-right: settings('gutter')/2;
  }
}

.pink {
  color: palette('pink');
}

.block {
  display: block;
}

.section {
  padding: 100px 0;
}

.swiper-container {
  width: 100%;
  height: 426px;
}

/* -------------------------------------------------------------------------- *
 * Custom Modules *
 * -------------------------------------------------------------------------- */

@import './_modules/nav';
@import './_modules/header';
@import './_modules/headerKids';
@import './_modules/cta';
@import './_modules/feature';
@import './_modules/apps';
@import './_modules/shows';
@import './_modules/member';
@import './_modules/trial';
@import './_modules/footer';
@import './_modules/swiper';
@import './_modules/snipe';
