.trial {
  background-color: #fff;
  color: palette('black');

  &__header {
    max-width: 850px;
  }

    &__pre {
      font-size: toRem(20px);
      font-weight: 800;
      margin-bottom: toRem(10px);
      text-transform: uppercase;
    }

    &__heading {
      color: palette('pink');
      font-size: toRem(44px);
      line-height: 1.2;
      margin: 0;
    }

  &__text {
    margin: toRem(30px) 0;
    max-width: 650px;
  }

  &__buttonGroup {
    align-items: center;
    display: flex;

    @media only screen and (max-width: toEm(600px)) {
      display: block;
    }
  }

    &__cta {
      margin-right: 15px;

      @media only screen and (max-width: toEm(600px)) {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }
}
