/* -------------------------------------------------------------------------- *
 * Resets, Normalizations, & Sane Defaults *
 * -------------------------------------------------------------------------- */

/* ------------- *
 * Global Styles *
 * ------------- */

:root {
  box-sizing: border-box;
  font-size: settings('root_font_size');
}

::selection {
  text-shadow: none;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

*:first-child {
  margin-top: 0;
}

*:last-child {
  margin-bottom: 0;
}

html {
  font-family: fontStack('default');
  font-smoothing: antialiased;
  line-height: settings('line_height');
  text-size-adjust: 100%;
}

/* -------------- *
 * Content Styles *
 * -------------- */

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

blockquote,
figure {
  margin: 1rem 0;
}

code,
kbd,
pre,
samp {
  font-family: fontStack(monospace);
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 1rem 0;
}

hr {
  border: 0;
  border-top: 1px solid currentColor;
  display: block;
  height: 1px;
  margin: 1rem 0;
  padding: 0;
}

img {
  height: auto;
  max-width: 100%;
}

small {
  font-size: 0.8em;
}

sub,
sup {
  font-size: 0.75em;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

table {
  border-collapse: collapse;
}

td,
th {
  padding: 0;
}

/* ----------- *
 * Form Styles *
 * ----------- */

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;

  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: settings('line_height');
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  border: 0;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

textarea {
  overflow-x: hidden;
  overflow-y: auto;
  resize: vertical;
}

/* ------------ *
 * Other Styles *
 * ------------ */

body {
  margin: 0;
}

details,
main {
  display: block;
}

progress {
  vertical-align: baseline;
}

summary {
  display: list-item;
}

template {
  display: none;
}
