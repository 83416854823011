.footer {
  background: linear-gradient(to right, palette('pink'), palette('orange'));
  color: #fff;
  font-size: toRem(16px);
  padding: 10px 0;

  @media only screen and (max-width: 1000px) {
    padding-bottom: 80px;
  }

  @media only screen and (max-width: 700px) {
    padding: 40px 0 120px;
  }

  &__content {
    align-items: center;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 700px) {
      flex-direction: column-reverse;
      text-align: center;
    }
  }

    &__copyright {
      margin: 0;
    }

    &__social {
      @extend %listReset;
      display: flex;
      justify-content: center;

      @media only screen and (max-width: 700px) {
        margin: 20px 0;
      }
    }

      &__socialItem {
        margin-left: 10px;

        &:first-child {
          margin-left: 0;
        }
      }

    &__logo {
      flex-shrink: 0;
    }
}
