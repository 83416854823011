.shows {
  &__heading {
    font-size: toRem(32px);
    line-height: 1.2;
    margin-bottom: 50px;
  }

  &__carousel {
    @media only screen and (max-width: 600px) {
      padding-right: 0;
    }
  }

  &__slide {
    align-items: flex-end;
    border-radius: 10px;
    display: flex;
    height: 426px;
    font-size: toRem(24px);
    position: relative;
    width: 259px;

    @media only screen and (max-width: 600px) {
      font-size: toRem(20px);

      &:last-child {
        margin-right: 20px;
      }
    }

    &--north-60 {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/north-of-60.jpg');
    }

    &--1491 {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/slide-1491.jpg');
    }

    &--querencia {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/slide-querencia.jpg');
    }

    &--last-land {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/the-last-land.jpg');
    }

    &--wild-west {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/wild-west.jpg');
    }

    &--out-west {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/quest-out-west.jpg');
    }

    &--chuck-and-fpk {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/chuck-and-fpk.jpg');
    }

    &--blackstone {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/blackstone.jpg');
    }

    &--nations-at-war {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/nations-war.jpg');
    }

    &--the-other-side {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/the-other-side.jpg');
    }

    &--amplify {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/amplify.jpg');
    }

    &--rabbit-fall {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/rabbit-fall.jpg');
    }

    &--tribal {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/slide-tribal.jpg');
    }

    &--teepee {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/slide-teepee.jpg');
    }

    &--firstContactAus {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/slide-first-contact-aus.jpg');
    }

    &--firstTalk {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/slide-first-talk.jpg');
    }

    &--spiritTalker {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/slide-sprit-talker.jpg');
    }

    &--firstContact {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/slide-first-contact.jpg');
    }

    &--louisSays {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/slide-louis-says.jpg');
    }

    &--mohawkGirls {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/slide-mohwak-girls.jpg');
    }

    &--moosemeatMarmalade {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/moosemeat-and-marmelade.jpg');
    }

    &--taken {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/slide-taken.jpg');
    }

    &--anaanasTent {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/slide-anaanas-tent.jpg');
    }

    &--cashingIn {
      background: linear-gradient(to bottom, rgba(palette('pink'), 0) 50%, rgba(palette('pink'), 0.8)),
        url('/images/slide-cashing-in.jpg');
    }

    /* Specific to the Kids landing page */

    &--tshakapesh {
      $mix: mix(palette('pink'), palette('orange'), $weight: 10%);
      background: linear-gradient(to bottom, rgba($mix, 0) 50%, rgba($mix, 0.8)),
        url('/images/slide-tshakapesh.jpg');
    }

    &--coyoteScience {
      $mix: mix(palette('pink'), palette('orange'), $weight: 10%);
      background: linear-gradient(to bottom, rgba($mix, 0) 50%, rgba($mix, 0.8)),
        url('/images/slide-coyote-science.jpg');
    }

    &--amysMorning {
      $mix: mix(palette('pink'), palette('orange'), $weight: 10%);
      background: linear-gradient(to bottom, rgba($mix, 0) 50%, rgba($mix, 0.8)),
        url('/images/slide-amys-morning.jpg');
    }

    &--end {
      background-image: url('/images/slide-end.jpg');
    }
  }

    &__content {
      padding: 40px 30px;
    }
}
