.feature {
  &__row {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &--reverse {
      flex-direction: row-reverse;
    }

    @media only screen and (max-width: toEm(900px)) {
      flex-direction: column-reverse;
    }
  }

    &__col {
      flex-basis: calc(50% - #{settings('gutter')});

      @media only screen and (max-width: toEm(900px)) {
        &--img {
          margin-bottom: settings('gutter');
          max-width: 400px;
        }
      }
    }

      &__heading {
        color: palette('pink');
        line-height: 1.2;
        font-size: toRem(32px);
        text-transform: uppercase;
      }
}
