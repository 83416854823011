.member {
  &__content {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    @media only screen and (max-width: toEm(900px)) {
      flex-direction: column-reverse;
    }
  }

    &__text {
      flex-basis: calc(50% - #{settings('gutter')});
    }

      &__heading {
        font-size: toRem(40px);
        font-weight: 800;
        line-height: 1.2;
        margin-bottom: 0;
        text-transform: uppercase;
      }

      &__subHeading {
        color: palette('pink');
        margin: 0;
        text-transform: uppercase;
      }

      &__list {
        margin: 2rem 0;

        @extend %listReset;
      }

        &__item {
          align-items: center;
          display: flex;
          margin-top: 15px;

          &:first-child {
            margin-top: 0;
          }

          &:before {
            content: url('/images/icon-check.svg');
            display: block;
            height: 34px;
            margin-right: 10px;
            width: 34px;
          }
        }

      &__img {
        flex-grow: 1;

        @media only screen and (max-width: toEm(900px)) {
          display: none;
        }
      }
}
