.nav {
  background-color: palette('black');
  left: 0;
  padding: 15px 0;
  position: fixed;
  top: -80px;
  transition: top 0.2s;
  width: 100%;
  z-index: zIndex('top');

  &.is-active {
    top: 0;
  }

  @media only screen and (max-width: toEm(1000px)) {
    bottom: -80px;
    top: auto;
    transition: bottom 0.2s;

    &.is-active {
      bottom: 0;
      top: auto;
    }
  }

  &__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

    &__logo {
      flex-shrink: 0;

      @media only screen and (max-width: toEm(1000px)) {
        margin-right: 30px;
      }

      @media only screen and (max-width: toEm(1000px)) {
        flex-basis: 70px;
      }
    }

    &__text {
      margin: 0;

      @media only screen and (max-width: toEm(1000px)) {
        display: none;
      }
    }
}