/* -------------------------------------------------------------------------- *
 * Helper Classes *
 * -------------------------------------------------------------------------- */

// Clear fix for containers with floated children
%clear {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.clear {
  @extend %clear;
}

// Remove default styles from list elements
%listReset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.listReset {
  @extend %listReset;
}

// Remove margins from child elements
%crushMargins {
  & > *:first-child { margin-top: 0; }
  & > *:last-child { margin-bottom: 0; }
}

.crushMargins {
  @extend %crushMargins;
}
